import React, { useState, useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { HAS_LINKS_BETWEEN_REQUESTS_AND_RESPONSES, LISTING_FIELD_REQUEST_LISTING_ID, LISTING_TYPE_REQUEST } from '../../config/configListing';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const { UUID } = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    distance
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const listingID = id;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const isVideo = extension => extension === 'mp4';
  const isModel = extension => extension === 'glb' || extension === 'gltf';
  // fetching file data for media

  const [files, setFiles] = useState([]);

  const fetchFiles = async listingID => {
    const response = await fetch(`/api/media/${listingID}/`);
    const data = await response.json();

    setFiles(data);
  };

  useEffect(() => {
    fetchFiles(listingID);
  }, [listingID]);
  // media to pass to first image
  const media = files.map(file => {
    const extension = file.url.split('.').pop();
    const URL = encodeURI(file.url);
    return {
      id: new UUID(file.url),
      type: isVideo(extension)
        ? 'video'
        : isModel(extension)
        ? 'model'
        : 'image',
      attributes: {
        variants: {
          'listing-card': {
            name: URL,
            width: 400,
            height: 300,
            url: URL,
          },
          'listing-card-2x': {
            name: URL,
            width: 400,
            height: 300,
            url: URL,
          },
        },
      },
    };
  });
  const firstImage = media && media.length > 0 ? media[0] : null;


  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          {LISTING_TYPE_REQUEST === publicData?.listingType &&
            HAS_LINKS_BETWEEN_REQUESTS_AND_RESPONSES &&
            <div className={css.authorInfo}>
              <NamedLink
                name="SearchPage"
                to={{ search: `?pub_${LISTING_FIELD_REQUEST_LISTING_ID}=${id}` }}
              >
                <FormattedMessage id="ListingCard.allProductsLink" />
              </NamedLink>
            </div>}
          {publicData?.[LISTING_FIELD_REQUEST_LISTING_ID] &&
            HAS_LINKS_BETWEEN_REQUESTS_AND_RESPONSES &&
            <div className={css.authorInfo}>
              <NamedLink
                name="ListingPageCanonical"
                params={{ id: publicData[LISTING_FIELD_REQUEST_LISTING_ID] }}
              >
                <FormattedMessage id="ListingCard.requestLink" />
              </NamedLink>
            </div>}
          {distance &&
            <div className={css.distance}>
              {distance.toFixed(2)} km
            </div>
          }
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
